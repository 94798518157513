import React, { Component } from "react";
import * as dotenv from "dotenv";
import JwtDecode from "jwt-decode";
import { browserHistory } from "react-router";
import Header from "./header.component";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";

// const dashboard = QuickSightEmbedding.embedDashboard(options);

// const dashboard = embedDashboard(options);

import axios from "axios";

export default class landingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      username: [],
    };
  }

  componentDidMount(props) {
    this.loginCheck();
    this.getQuickSightUrl();
    // let user= localStorage.getItem('username');
    // this.setState({username: user})
  }

  loginCheck() {
    let user = localStorage.getItem("username");
    this.setState({ username: user });
    user === null ? this.props.history.push("/") : console.log("loggedin");
  }

  refreshPage() {
    // console.log("calles");
    window.location.reload();
  }

  getQuickSightUrl() {
    // this.refreshPage();
    // axios.post(`https://j03lm8p2vd.execute-api.us-east-2.amazonaws.com/quick-sight-screen/quicksight`)
    //   .then(res => {
    //     console.log(res);
    //     console.log(res.data.url);
    //     url=res.data.url;
    //  })
    let dashboardID = {
      dashboardID: "d159d597-3570-4ec0-b84e-f80b7fb05471",
    };
    //console.log("Isn");
    axios({
      url: "https://j03lm8p2vd.execute-api.us-east-2.amazonaws.com/quick-sight-screen/revised-waterheater-quicksight-dashboard",
      method: "post",
      data: dashboardID,
      // headers: {
      //     'X-Frame-Options': 'SAMEORIGIN',
      //     'Content-Type': 'application/json'
      // }
    })
      .then((res) => {
        // console.log("url-",res.data.url)
        this.setState({ users: res.data.url });
        var containerDiv = document.getElementById("embeddingContainer");
        var URL = res.data.url;
        var options = {
          // replace this dummy url with the one generated via embedding API
          url: URL,
          container: containerDiv,
          scrolling: "no",
          height: "710px",
          width: "1390px",
          footerPaddingEnabled: true,
          printEnabled: false,
        };
        dashboard = QuickSightEmbedding.embedDashboard(options);
        // window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
    var dashboard;
  }

  render() {
    return (
      <div className="topheight">
        <Header />
        <div id="embeddingContainer" className="holds-the-iframe"></div>
      </div>
    );
  }
}
