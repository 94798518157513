import React, { Component } from "react";
import axios from "axios";

export default class Login extends Component {
  //const Login = () => {
  constructor(props) {
    super(props);
    // this.state = this.getIntialState();

    this.state = {
      username: "",
      password: "",
      rememberMe: false,
    };
  }

  handleChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;
    this.setState({ [input.name]: value });
    this.setState({ [input.password]: value });
  };

  handleFormSubmit = (e) => {
    const { rememberMe, username, password } = this.state;
    localStorage.setItem("rememberMe", rememberMe);
    localStorage.setItem("username", username ? username : "");
    localStorage.setItem("password", password ? password : "");
    username == "network" && password == "khnetwork@123"
      ? this.props.history.push("/landingPage")
      : //this.props.history.push("/");
        alert("Please Enter Valid Username/Password");
    //this.getQuickSightUrl();
  };

  componentDidMount() {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const username = rememberMe ? localStorage.getItem("username") : "";
    const password = rememberMe ? localStorage.getItem("password") : "";
    this.setState({ username, password, rememberMe });
    //console.log("called");
  }

  render() {
    return (
      <div className="auth-wrapper bodyBlue ">
        <div className="Home-bg">
          <div className="auth-inner">
            <form onSubmit={this.handleFormSubmit}>
              <h3 className="font-link">Sign In</h3>

              <div className="form-group ">
                <label className="font-link">User Name</label>
                <input
                  className="form-control"
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label className="font-link">Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label>
                  <input
                    name="rememberMe"
                    className="font-link"
                    checked={this.state.rememberMe}
                    onChange={this.handleChange}
                    type="checkbox"
                  />{" "}
                  Remember me
                </label>
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-block font-link'"
              >
                Submit
              </button>

              <p className="forgot-password text-right font-link'">
                Forgot <a href="#">password?</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
