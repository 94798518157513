import React, { useState, useEffect } from "react";
import logo from "../images/keenhomepro_logo.svg";
import Login from "./login.component";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
  useHistory,
} from "react-router-dom";

function Header() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    let usr = localStorage.getItem("username");
    let pwd = localStorage.getItem("password");
    setUsername(usr);
    setPassword(pwd);
  });

  const history = useHistory();

  // function onLoad(){
  //   let usr = localStorage.getItem('username');
  //   setUsername(usr);
  // }

  function logOut() {
    localStorage.clear();
    history.push("/");
    window.location.reload(false);
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="container AppContainer">
        <img className="logoSize" src={logo} alt="Logo" />
        {password == "khnetwork@123" ? (
          <h4 className="subHeader font-link"></h4>
        ) : null}
        {password == "khnetwork@123" ? (
          <Nav>
            <div className="Header_text">KHpro Network</div>{" "}
            <div className="wel font-link"> Welcome</div>
            <NavDropdown title={username}>
              <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        ) : null}
      </div>
    </nav>
  );
}

export default Header;
