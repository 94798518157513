import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './images/connectm-logo.svg'
import Header from "./components/header.component";
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect } from "react-router-dom";

import Login from "./components/login.component";
import SignUp from "./components/signup.component";
import landingPage from './components/landingpage.component';

function App() {


  return (<Router>
    <div className="App">
      <Header/>
      
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/Login' component={Login} />
            <Route path="/landingPage" component={landingPage} />
          </Switch>
        
    </div></Router>
  );
}

export default App;